.container{
    display: flex;
    flex-flow: column;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-color: pink;
}
.text-sync{ 
    width: 100%;
    height: 70vh;
    flex-basis: 80vh;
    background-color: rgb(204, 204, 255);
    display: flex;
    flex-flow: row;
    justify-content: center;
    padding: 4%;
    .send, .receive{
        margin: 0 3%;
        padding: 1%;
        // overflow: scroll;
    }
}
.file-sync{
    width: 100%;
    height: 30vh;
    background-color: white;
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    .dropbox{
        flex-basis: 70%;
        height: 100%;
        background-color: #F0F0F0;
        border: dashed 1px rgb(162, 162, 162);
        .upload-icon{
            color: grey;
        }
        cursor: pointer;
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        .dropzone{
            width: 100%;
            height: 90%;
            flex-basis: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            padding: 10% 0;
        }
    }
    .reciever{
        position: relative;
        flex-basis: 30%;
        height: 100%;
        // background-color: bisque;
        // padding: 10%;
        .download-zone{
            height: 100%;
            display: flex;
            flex-flow: column;
            align-items: center;
            div{
                height: 80%;
                width: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            p{
                margin-top: 3%;
            }
        }
    }
}


/* Hide scrollbar for Chrome, Safari, and Opera */
.hide-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  /* Customize scrollbar for Chrome, Safari, and Opera */
  .custom-scrollbar::-webkit-scrollbar {
    width: 5px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: #c6ffd2;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #ccafff;
    border-radius: 6px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  