.preloader{
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #000;
    position: fixed;
    color: white;
    z-index: 101;

    font-family: "kallisto", sans-serif;
    font-weight: 300;
    font-style: normal;
    font-size: 0.5rem;
    letter-spacing: 0.4em;
}