.navbar {
    height: 6.5rem;
    position: absolute;
    background-color: transparent;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.char{
    display: inline-block;
}
.navbar--container {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 1rem;
    h1{
        // font-family: 'BestermindRegular';
        font-family: "kallisto", sans-serif;
        font-weight: 300;
        font-style: normal;
        color: #89d3ce;
        font-size: 2.5rem;
        user-select: none;
        cursor: pointer;
    }
    h4{
        color: white;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-size: 0.75rem;
    }
}
.links{
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;
    justify-content: center;
    color: white;
    position: absolute;
    top: 50%;
    left: 4%;
    z-index: 100;
    background: transparent;
    list-style: none;
    li, a, span{
        background: transparent;
        color: white;
        text-transform: uppercase;
        font-size: 0.75rem;
        letter-spacing: 0.1em;
    }
    li{
    padding-top: 0.55rem;
    padding-bottom: .55rem;
    }
    h5{
        margin-left: 30%;
        cursor: pointer;
    }
    .line{
        width: 2rem;
        height: 1px;
        display: block;
        border: 1px solid white;
        transition-property: all;
        transition-timing-function: cubic-bezier(.4,0,.2,1);
        transition-duration: .15s;
        margin-right: 1rem;
        
    }
    a{
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
    }
    a:hover{
        span{
            width: 4rem;
        }
    }
}

.navLink--container {
    margin-top: 5rem;
}




@media (max-width:1100px) {
    .navbar--container > h1{
        font-size: 2.2rem;
    }
    .nav-menu{
        font-size: 2.2rem;
        margin-top: -1.5rem;
    }
}

@media (max-width:800px) {
    .navLink--container {
        margin-top: 3rem;
    }
}


@media (max-width:600px) {
    .nav-menu{
        color: var(--primary-2);
    }
    .navbar--container > h1{
        font-size: 2rem;
    }
    .nav-menu:hover{
        color: var(--primary-2);
    }
    .MuiDrawer-paper{
      border-radius: 0 !important;
    }
}


@media only screen and (min-device-width: 320px) and (max-device-width: 
  480px) and (-webkit-device-pixel-ratio: 2) and (device-aspect-ratio: 2/3) {
    .navbar {
        height: 5rem;
    }
    .navbar--container {
        padding: 0 1rem;
        margin-top: 0rem;
    }
    .navbar--container > h1 {
        font-size: 1.5rem;
    }
}