.arrow{
    position: absolute;
    width: 50px;
    height: 50px;
    right: 2%;
    bottom: 5%;
    z-index: 100;
    cursor: pointer;
    background: transparent;
    transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.arrow:hover{
    transform: scaleY(1.5);
}

#galaxy-container{
    overflow: auto;
    background-color: black;
}

body::-webkit-scrollbar {
    display: none; /* Hide scrollbar for Webkit browsers */
  }