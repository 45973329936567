.footer {
    font-family: 'var(--primaryFont)', sans-serif;
    height: 60px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
}

.footer p {

    font-family: "kallisto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.footer p span {
    font-size: 30px;
}